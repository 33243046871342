$(document).ready(function() {

    $('#scaffoldTarpaulinList').DataTable({
        "processing": true,
        "serverSide": true,
        "ajax": {
            "url": $('#scaffoldTarpaulinList').data('url'),
            "dataType": "json",
            "type": "post",
            "cache": false
        },
        "columns": [
            {"data": "order_status_id"},
            {"data": "id"},
            {"data": "order_date"},
            {"data": "company_name"},
            {"data": "company_city"},
            {"data": "id"},
        ],
        "columnDefs": [
            {
                "targets": 0,
                "className": "text-center",
                "width": '50px',
                "sortable": false,
                "createdCell": function (td, cellData, rowData, row, col) {
                    $(td).html(rowData.order_status_icon);
                }
            },
            {
                "targets": 1,
                "width": '120px',
                "createdCell": function (td, cellData, rowData, row, col) {
                    $(td).html('<a class="d-flex align-items-center" href="' + rowData.details_link + '">' + $(td).html() +
                        (rowData.unread_messages
                            ? '<span class="amount-indicator position-relative left-unset bottom-unset ms-auto" title="' + rowData.unread_messages + ' ungelesene Nachricht' + ( rowData.unread_messages > 1 ? 'en' : '' ) + '">\n' +
                            rowData.unread_messages +
                            '</span>'
                            : '') +
                        '</a>');
                }
            },
            {
                "targets": 2,
                "width": '120px',
                "createdCell": function (td, cellData, rowData, row, col) {
                    $(td).html(rowData.order_date_fmt);
                }
            },
            {
                "targets": 5,
                "sortable": false,
                "className": "text-center",
                "width": '50px',
                "createdCell": function (td, cellData, rowData, row, col) {
                    $(td).html('<a class="show-details" href="' + rowData.details_link + '"><i title="Details anzeigen" class="fa fa-eye"></i></a>');
                }
            }
        ],
        "order": [[1, "desc"]],
        "searching": false,
        "lengthChange": false,
        "language": {
            "url": appUrl() + "/i18n/dataTables.de.lang.json"
        },
        "initComplete": function (settings, json) {
            $('#scaffoldTarpaulinList').show();
        }
    });

    if ($(document).find('.message.unread').length) {
        $('main').animate({
            scrollTop: $(document).find('.message.unread').first().offset().top - 80
        });
    }

    $(document).on('change', '#attachments', function () {
        var amount = $(this).get(0).files.length;

        if (amount > 5) {
            $(this).addClass('is-invalid');
            $(this).val('');
            $(this).parent().append('' +
                '<span class="invalid-feedback show" role="alert">' +
                    '<strong>Sie können maximal 5 Dateien hochladen.</strong>' +
                '</span>');
        } else {
            $(this).removeClass('is-invalid');
            $(this).parent().find('.invalid-feedback').remove();
        }
    });

    $(document).on('change', '.chat-message-files input', function () {
        var amount = $(this).get(0).files.length;
        var label = $(this).parent();

        if (amount) {
            if (!label.hasClass('text-primary')) {
                label.addClass('text-primary');
            }
            label.parent().find('.delete-files').removeClass('d-none');
            label.find('.amount-indicator').removeClass('d-none');
            label.find('.amount-indicator').html(amount);
        } else {
            label.removeClass('text-primary');
            if (!label.find('.amount-indicator').first().hasClass('d-none')) {
                label.find('.amount-indicator').addClass('d-none');
                label.find('.amount-indicator').html('');
            }
            if (!label.parent().find('.delete-files').first().hasClass('d-none')) {
                label.parent().find('.delete-files').addClass('d-none');
            }
        }
    });

    $(document).on('click', '.chat-message .delete-files', function () {
        $(this).parents('.chat-message').find('.chat-message-files input').val('');
        $(this).parents('.chat-message').find('.chat-message-files input').change();
    });

    $(document).on('change', 'select[name=amount_mesh]', function() {
        if ($(this).val() && $(this).val() > 0) {
            $('input[name=mesh]').prop('checked', true);
        } else {
            $('input[name=mesh]').prop('checked', false);
            $(this).val('0');
        }
    });

    $(document).on('change', 'select[name=amount_pvc]', function() {
        if ($(this).val() && $(this).val() > 0) {
            $('input[name=pvc]').prop('checked', true);
        } else {
            $('input[name=pvc]').prop('checked', false);
            $(this).val('0');
        }
    });

    $(document).on('change', 'input[name=mesh]', function() {
        if ($(this).prop('checked')) {
            $('select[name=amount_mesh]').val('1');
        } else {
            $('select[name=amount_mesh]').val('0');
        }
    });

    $(document).on('change', 'input[name=pvc]', function() {
        if ($(this).prop('checked')) {
            $('select[name=amount_pvc]').val('1');
        } else {
            $('select[name=amount_pvc]').val('0');
        }
    });

    $(document).on('change', '.modal select[name=scaffold_tarpaulin]', function (e) {
        let id = $(this).val();
        let price = $(document).find('[name=price_per_unit]');

        $.ajax({
            type: 'get',
            url: appUrl() + '/geruestplanen/preis/' + id,
            success: function (response) {
                price.val(response.price);
            }
        })
    });

    /*
    |--------------------------------------------------------------------------
    | Add Scaffold Tarpaulin to Order
    |--------------------------------------------------------------------------
    */
    $(document).on('click', 'button.add-scaffold-tarpaulin-to-order', addScaffoldTarpaulinToOrder);

    function addScaffoldTarpaulinToOrder(e) {
        e.preventDefault();

        let modal = $(document).find('#add-item-modal')
        let data = modal.find(':input').serializeArray();

        $.ajax({
            url: appUrl() + '/geruestplanen/neu/geruestplane/hinzufuegen',
            type: 'post',
            data: data,
            success: function (response) {

                if (response.success) {
                    $(document).find('#items-wrapper')
                        .append(response.view);
                }

                modal.on('hidden.bs.modal', function (e) {
                    if (response.modal) {
                        $(document).find('#add-item-modal').replaceWith(response.modal);
                    }
                })

                modal.find('button[data-bs-dismiss="modal"]').first().click();
            },
            error: function (response) {

                modal.find('.invalid-feedback').remove();
                modal.find('.is-invalid').removeClass('is-invalid');

                let errors = response.responseJSON.errors;

                $.each(errors, function (key, value) {
                    modal.find('[name=' + key + ']').addClass('is-invalid')
                        .after('<div class="invalid-feedback" role="alert">'+ value[0] +'</div>');
                });
            }
        })
    }

    /*
    |--------------------------------------------------------------------------
    | Edit Scaffold Tarpaulin
    |--------------------------------------------------------------------------
    */
    $(document).on('click', '.scaffold-tarpaulin-item-create', createScaffoldTarpaulin);

    $(document).on('click', '.scaffold-tarpaulin-item-edit', editScaffoldTarpaulin);

    $(document).on('click', '.scaffold-tarpaulin-item-remove', removeScaffoldTarpaulin);

    function createScaffoldTarpaulin(e) {
        e.preventDefault();

        let id = $(this).data('target');

        let modal = $(document).find('#create-position-modal')
        let data = modal.find(':input').serializeArray();

        $.ajax({
            url: appUrl() + '/geruestplanen/details/'+id+'/geruestplane/hinzufuegen',
            type: 'post',
            data: data,
            success: function (response) {

                if (response.success) {
                    $(document).find('#items-wrapper')
                        .append(response.view);

                    reloadTotalPrice(id);
                }

                modal.on('hidden.bs.modal', function (e) {
                    if (response.modal) {
                        $(document).find('#create-position-modal').html(response.modal);
                    }
                })

                modal.find('button[data-bs-dismiss="modal"]').first().click();
            },
            error: function (response) {

                modal.find('.invalid-feedback').remove();
                modal.find('.is-invalid').removeClass('is-invalid');

                let errors = response.responseJSON.errors;

                $.each(errors, function (key, value) {
                    modal.find('[name=' + key + ']').addClass('is-invalid')
                        .after('<div class="invalid-feedback" role="alert">'+ value[0] +'</div>');
                });
            }
        })
    }

    function editScaffoldTarpaulin(e) {
        e.preventDefault();

        let order_id = $('.card.order').data('id');
        let id = $(this).data('target');

        let modal = $(document).find('#edit-item-modal-'+ id)
        let data = modal.find(':input').serializeArray();

        $.ajax({
            url: appUrl() + '/geruestplanen/details/geruestplane/bearbeiten/' + id,
            type: 'post',
            data: data,
            success: function (response) {

                if (response.success) {
                    $(document).find('[data-id="'+id+'"]').parent()
                        .replaceWith(response.view);

                    reloadTotalPrice(order_id);
                }

                modal.on('hidden.bs.modal', function (e) {
                    if (response.modal) {
                        $(document).find('#edit-item-modal-' + id).replaceWith(response.modal);
                    }
                })

                modal.find('button[data-bs-dismiss="modal"]').first().click();
            },
            error: function (response) {

                modal.find('.invalid-feedback').remove();
                modal.find('.is-invalid').removeClass('is-invalid');

                let errors = response.responseJSON.errors;

                $.each(errors, function (key, value) {
                    modal.find('[name=' + key + ']').addClass('is-invalid')
                        .after('<div class="invalid-feedback" role="alert">'+ value[0] +'</div>');
                });
            }
        })
    }

    function removeScaffoldTarpaulin(e) {
        e.preventDefault();

        let order_id = $('.card.order').data('id');
        let id = $(this).data('target');

        let modal = $(document).find('#delete-item-modal-'+ id)

        $.ajax({
            url: appUrl() + '/geruestplanen/details/geruestplane/loeschen/' + id,
            type: 'post',
            success: function (response) {

                if (response.success) {
                    $(document).find('[data-id="'+id+'"]').parent()
                        .replaceWith('');

                    reloadTotalPrice(order_id);
                }

                modal.on('hidden.bs.modal', function (e) {
                    if (response.modal) {
                        $(document).find('#edit-item-modal-' + id).replaceWith('');
                    }
                })

                modal.find('button[data-bs-dismiss="modal"]').first().click();
            },
            error: function (response) {}
        });
    }

    function reloadTotalPrice(id) {
        $.ajax({
            url: appUrl() + '/geruestplanen/details/' + id + '/preis/aktualisieren',
            type: 'post',
            success: function (response) {
                if (response.success) {
                    $('.purchasing-price.total').html(response.value)
                }
            },
            error: function (response) {}
        });
    }

    /*
    |--------------------------------------------------------------------------
    | Remove Scaffold Tarpaulin from Order
    |--------------------------------------------------------------------------
    */
    $(document).on('click', '.scaffold-tarpaulin-item .options .remove', function () {
        $(this).parents('.scaffold-tarpaulin-item').remove();
    });
});

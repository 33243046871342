require('./bootstrap');

import Dropzone from 'dropzone';
Dropzone.autoDiscover = false;

require('./datepicker_de');
require('./backoffice');
require('./general');
require('./scaffold_tarpaulin');
require('./seminar');
require('./order');

$(document).ready(function() {

    if ($(document).find('.is-invalid').length) {
        $('main').animate({
            scrollTop: $(document).find('.is-invalid').first().offset().top - 80
        });
    }

    $(document).find('textarea.auto-height').each(function() {
        this.style.height = 'auto';
        this.style.height = (this.scrollHeight) + 2 + 'px';
    });

    $(document).on('keyup', 'textarea.auto-height', function() {
        this.style.height = 'auto';
        this.style.height = (this.scrollHeight) + 2 + 'px';
    });

    $(document).find('.dropzone').each(function() {

        let id = $(this).attr('id');
        let type = $(this).data('type');

        $('#'+id).addClass('dropzone');

        var dropzone = new Dropzone('#'+id, {
            uploadMultiple: true,
            timeout: 180000,
            url: $(this).data('url'),
            headers: {
                'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')
            },
            success: function(file, response) {
                if (response.hasOwnProperty('success') && response.success) {
                    $('#'+id).parents('.dropzone-wrapper').find('.result-table').html(response.preview);
                    dropzone.removeAllFiles();
                }
            },
            complete: function(file) {

            },
        });

        dropzone.options.parallelUploads = 5;

        dropzone.on("sending", function(file, xhr, formData) {
            // Will send the filesize along with the file as POST data.
            formData.append('type', type);
        });

    });

    $(document).find('.dropzone-wrapper').on('click', '.btn-delete', function (e) {
        e.preventDefault();


        let order = $(this).parents('.card.order').data('id');
        let target = $(this).data('id');
        let wrapper = $(this).parents('.dropzone-wrapper');

        $.ajax({
            url: appUrl() + '/geruestplanen/details/'+ order +'/anhang/'+ target +'/loeschen',
            method: 'get',
            success: function (response) {
                if (response.hasOwnProperty('success') && response.success) {
                    wrapper.find('.result-table').html(response.preview);
                }
            }
        });

    });

    $(document).find('.dropzone-wrapper').on('click', '.btn-save-name', function(e) {

        let order = $(this).parents('.card.order').data('id');
        let target = $(this).data('id');
        let button = $(this);

        if (target) {
            $.ajax({
                url: appUrl() + '/geruestplanen/details/'+ order +'/anhang/'+ target +'/umbennen',
                type: 'post',
                data: {
                    name: $(this).parents('.input-group').find('input').val()
                },
                success: function() {
                    button.parents('.input-group')
                        .find('input')
                        .after('<div class="data-table-option check"></div>');

                    setTimeout(function() {
                        button.parents('.input-group').find('.data-table-option').fadeOut('slow');
                    },1000);
                },
                error: function() {
                    button.parents('.input-group')
                        .find('input')
                        .after('<div class="data-table-option error"></div>');

                    setTimeout(function() {
                        button.parents('.input-group').find('.data-table-option').fadeOut('slow');
                    },1000);
                }
            });
        }
    });
});

window.appUrl = function appUrl() {
    let appUrl = document.head.querySelector('meta[name="app-url"]');
    return appUrl ? appUrl.content : '';
};

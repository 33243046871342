$(document).ready(function() {
    if ($('#backoffice').length) {

        $('#importForm').on('submit', function(e) {
            e.preventDefault();

            $('#importAjaxProgressBar').parent().css('display', 'none');
            $('#importAjaxProgress .modal-footer').addClass('d-none');

            var formData = new FormData(this);

            var url = $(this).attr('action');

            var xhr = new XMLHttpRequest();
            xhr.open('POST', url, true);

            xhr.send(formData);
            xhr.onreadystatechange = function () {
                if (xhr.status == 200) {
                    if (xhr.readyState == XMLHttpRequest.LOADING) {
                        eval(xhr.response.substring(xhr.response.lastIndexOf('___') + 3));
                    }
                    if (xhr.readyState == XMLHttpRequest.DONE) {
                        $('#importAjaxProgress .modal-footer').removeClass('d-none');
                    }
                }
            }
        });
    }
});

jQuery(function($){
    $.datepicker.regional['de'] =
    {   clearText: 'löschen',
        clearStatus: 'aktuelles Datum löschen',
        closeText: 'schließen',
        closeStatus: 'ohne Änderungen schließen',
        prevText: '<<<',
        prevStatus: 'letzten Monat zeigen',
        nextText: '>>>',
        nextStatus: 'nächsten Monat zeigen',
        currentText: 'heute',
        currentStatus: '',
        monthNames: ['Januar','Februar','März','April','Mai','Juni','Juli','August','September','Oktober','November','Dezember'],
        monthNamesShort:['Jan','Feb','Mär','Apr','Mai','Jun', 'Jul','Aug','Sep','Okt','Nov','Dez'],
        monthStatus: 'anderen Monat anzeigen', yearStatus: 'anderes Jahr anzeigen',
        weekHeader: 'Wo',
        weekStatus: 'Woche des Monats',
        dayNames: ['Sonntag','Montag','Dienstag','Mittwoch','Donnerstag','Freitag','Samstag'],
        dayNamesShort: ['So','Mo','Di','Mi','Do','Fr','Sa'],
        dayNamesMin: ['So','Mo','Di','Mi','Do','Fr','Sa'],
        dayStatus: 'Setze DD als ersten Wochentag',
        dateStatus: 'Wähle D, M d',
        dateFormat: 'dd.mm.yy',
        firstDay: 1,
        initStatus: 'Wähle ein Datum',
        isRTL: false
    };
    $.datepicker.setDefaults($.datepicker.regional['de']);
});

$(document).ready(function () {

    $(document).on('click', '.datepicker_set i', function () {
        var picker = $(this).parents('.datepicker_set').find('.datepicker, .drp').first();
        picker.focus();
    });

    $('.drpfrom').each(function(e)
    {
        var obj = $(this);
        var minDate =   obj.data('daterange_min');
        var maxDate =   obj.data('daterange_max');
        var set_name =  obj.data('daterange_id');
        let container = obj.data('container') ?? $(this).parent();

        obj.datepicker($.extend({}, $.datepicker.regional["de"],
            {
                showButtonPanel : true,
                closeText: 'Löschen',
                onClose: function (dateText, inst) {
                    if ($(window.event.srcElement).hasClass('ui-datepicker-close'))
                    {
                        document.getElementById(this.id).value = '';
                    }
                },
                beforeShow: function (input, obj) {
                    $(input).after($(input).datepicker('widget'));
                },
                changeMonth: true,
                changeYear: true,
                minDate: minDate,
                maxDate: maxDate,
                yearRange: "-100y:+100y",
                container: container,
                onSelect: function ()
                {
                    var startDate = $(this).datepicker('getDate');
                    $('.drpto[data-daterange_id="' + set_name + '"]').datepicker('option', 'minDate', startDate);
                    $(this).change();
                },
                showWeek: true,
                firstDay: 1,
            }));
    });

    $('.drpto').each(function(e)
    {
        var obj = $(this);
        var minDate =obj.data('daterange_min');
        var maxDate =  obj.data('daterange_max');
        var set_name =  obj.data('daterange_id');

        obj.datepicker($.extend({}, $.datepicker.regional["de"],
            {
                showButtonPanel : true,
                closeText: 'Löschen',
                onClose: function (dateText, inst) {
                    if ($(window.event.srcElement).hasClass('ui-datepicker-close'))
                    {
                        document.getElementById(this.id).value = '';
                    }
                },
                changeMonth: true,
                changeYear: true,
                minDate: minDate,
                maxDate: maxDate,
                yearRange: "-100y:+100y",
                onSelect: function ()
                {
                    var startDate = $(this).datepicker('getDate');
                    $('.drpfrom[data-daterange_id="' + set_name + '"]').datepicker('option', 'maxDate', startDate);
                    $(this).change();
                },
                showWeek: true,
                firstDay: 1,
            }));
    });

});

$(document).ready(function () {

    const seminarList = $('#seminar-list');

    seminarList.DataTable({
        "processing": true,
        "serverSide": true,
        "ajax": {
            "url": seminarList.data('url'),
            "dataType": "json",
            "type": "post",
            "cache": false
        },
        "columns": [
            {"data": "order_status"},
            {"data": "request_id"},
            {"data": "request_date"},
            {"data": "company"},
            {"data": "seminar"},
            {"data": "seminar_location"},
            {"data": "seats_requested"},
            {"data": "request_id"},
        ],
        "columnDefs": [
            {
                "targets": 0,
                "className": "text-center",
                "width": '50px',
                "sortable": false,
                "createdCell": function (td, cellData, rowData, row, col) {
                    $(td).html(rowData.order_status_icon);
                }
            },
            {
                "targets": 1,
                "className": "text-center",
                "width": '80px'
            },
            {
                "targets": 2,
                "width": '100px',
                "createdCell": function (td, cellData, rowData, row, col) {
                    $(td).html(rowData.request_date_fmt);
                }
            },
            {
                "targets": 6,
                "className": "text-center",
                "width": '80px'
            },
            {
                "targets": 7,
                "sortable": false,
                "className": "text-center",
                "width": '50px',
                "createdCell": function (td, cellData, rowData, row, col) {
                    $(td).html('<a class="show-details" href="' + rowData.details_link + '"><i title="Details anzeigen" class="fa fa-eye"></i></a>');
                }
            }
        ],
        "order": [[1, "desc"]],
        "searching": false,
        "lengthChange": false,
        "language": {
            "url": appUrl() + "/i18n/dataTables.de.lang.json"
        },
        "initComplete": function (settings, json) {
            $('#seminar-list').show();
        }
    });


});

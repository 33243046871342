$.fn.inputFilter = function (inputFilter)
{
    return this.on("input keydown keyup mousedown mouseup select contextmenu drop", function ()
    {
        if (inputFilter(this.value))
        {
            this.oldValue = this.value;
            this.oldSelectionStart = this.selectionStart;
            this.oldSelectionEnd = this.selectionEnd;
        }
        else if (this.hasOwnProperty("oldValue"))
        {
            this.value = this.oldValue;
            this.setSelectionRange(this.oldSelectionStart, this.oldSelectionEnd);
        }
    });
};

$(".numeric").inputFilter(function (value)
{
    return /^-?\d*[.,]?\d{0,2}$/.test(value);
});

$(".numericInteger").inputFilter(function (value)
{
    return /^-?\d{0,6}$/.test(value);
});

$(document).on('change', '.numeric:not(.positive)', function () {

    var el = $(this);
    var value = el.val();

    var timeout = setTimeout(function ()
    {
        var dec = stringToDecimal(value, true);
        var float = stringToFloat(dec, true);
        float = number_format(float, 0, ',', '.');

        if (value == '')
        {
            el.val('');
        }
        else
        {
            el.val(float);
        }
    }, 0);
});

$(document).on('change', '.numeric.positive', function (e) {

    e.preventDefault();
    e.stopImmediatePropagation();

    var el = $(this);
    var value = el.val();

    var timeout = setTimeout(function ()
    {
        var dec = stringToDecimal(value, false);
        var float = stringToFloat(dec, false);
        float = number_format(float, 0, ',', '.');

        if (value == '')
        {
            el.val('');
        }
        else
        {
            el.val(float);
        }
    }, 0);
});

$(document).on('change', '.numericInteger:not(.positive)', function () {

    var el = $(this);
    var value = el.val();

    var timeout = setTimeout(function ()
    {
        var dec = stringToDecimal(value, true);
        var float = stringToFloat(dec, true);
        float = number_format(float, 0, ',', '');

        if (value == '')
        {
            el.val('');
        }
        else
        {
            el.val(float);
        }
    }, 0);
});

$(document).on('change', '.numericInteger.positive', function () {

    var el = $(this);
    var value = el.val();

    var timeout = setTimeout(function ()
    {
        var dec = stringToDecimal(value, false);
        var float = stringToFloat(dec, false);
        float = number_format(float, 0, ',', '');

        if (value == '')
        {
            el.val('');
        }
        else
        {
            el.val(float);
        }
    }, 0);
});


window.stringToDecimal = function stringToDecimal(string, allow_negative)
{
    if(allow_negative === undefined || allow_negative === false)
    {
        string = string.replace(/[^0-9.,]/g, '');
    }
    else
    {
        if(string.charAt(0) === '-')
        {
            string = '-' + string.replace(/[^0-9.,]/g, '');
        }
        else
        {
            string = string.replace(/[^0-9.,-]/g, '');
        }
    }
    string = string.replace('.', ',');
    return removeAllButLast(string, ',');
};

window.stringToFloat = function stringToFloat(string, allow_negative)
{
    if(allow_negative !== undefined && allow_negative === true && string.charAt(0) === '-')
    {
        var factor = -1;
    }
    else
    {
        var factor = 1;
    }
    string = string.replace(/[^0-9.,]/g, '');
    var _float = removeAllButLast(string.replace(',', '.'), '.');

    return parseFloat(_float) * factor;
};

window.number_format = function number_format (number, decimals, dec_point, thousands_sep) {
    // Strip all characters but numerical ones.
    number = (number + '').replace(/[^0-9+\-Ee.]/g, '');
    var n = !isFinite(+number) ? 0 : +number,
        prec = !isFinite(+decimals) ? 0 : Math.abs(decimals),
        sep = (typeof thousands_sep === 'undefined') ? ',' : thousands_sep,
        dec = (typeof dec_point === 'undefined') ? '.' : dec_point,
        s = '',
        toFixedFix = function (n, prec) {
            var k = Math.pow(10, prec);
            return '' + Math.round(n * k) / k;
        };
    // Fix for IE parseFloat(0.55).toFixed(0) = 0;
    s = (prec ? toFixedFix(n, prec) : '' + Math.round(n)).split('.');
    if (s[0].length > 3) {
        s[0] = s[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, sep);
    }
    if ((s[1] || '').length < prec) {
        s[1] = s[1] || '';
        s[1] += new Array(prec - s[1].length + 1).join('0');
    }
    return s.join(dec);
};

window.toDecimal = function toDecimal(number, decimals, seperator)
{
    if (seperator != undefined)
    {
        return number_format(number, decimals ?? 2, ',', '.')
    } else {
        return number_format(number, decimals ?? 2, ',', '')
    }
}

window.toCurrency = function toCurrency(number, seperator)
{
    return toDecimal(number ,2 , seperator) + ' €';
}
